import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';
import { IResignationFileApprovalStatusUpdateRequest, IFileBytesView } from '@/interfaces/_auto/api';

import { AxiosPromise } from 'axios';

const API_CONTROLLER = 'admin/resignation/file';

export class AdminResignationFileApi {

  static getFile(resignationFileId: number): AxiosPromise<IFileBytesView> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationFileId}`;
    return authAxios.get(url);
  }

  static uploadResignationDocuments(resignationId, files, onUploadProgress) {

    const formData = new FormData();

    for (const file of files) {
      formData.append(file.name, file);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (onUploadProgress) {
      // @ts-ignore
      config.onUploadProgress = onUploadProgress;
    }

    return authAxios.post(`${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`, formData, config);
  }

  static updateResignationFileApprovalStatus(resignationFileId: number, updateRequest: IResignationFileApprovalStatusUpdateRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationFileId}/approval-status`;
    return authAxios.post(url, updateRequest);
  }

  static deleteFile(resignationFileId) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationFileId}`;
    return authAxios.delete(url);
  }
}
