import Component from 'vue-class-component';
import Vue from 'vue';
import { IAdminEnumDictionary } from '@/interfaces/IAdminEnumDictionary';


@Component
export default class EnumsMixin extends Vue {

  async loadAdminEnums() {
    await this.$store.dispatch('enums/getAdminEnums');
  }

  get adminEnums(): IAdminEnumDictionary {
    return this.$store.state.enums.adminEnums;
  }
}
