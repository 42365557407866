<template>
  <div>
    <v-data-table disable-filtering
                  disable-pagination
                  disable-sort
                  :headers="resignationErrorFields"
                  hide-default-footer
                  :items="resignationErrors"
                  outlined>

      <template #item.errorTypes="{item: {errorTypes}}">
        <ul class="m-0 p-0">
          <li v-for="et in errorTypes"
              :key="et.number">
            {{ et.description }}
          </li>
        </ul>
      </template>

      <template #item.t_createdDate="{item: { createdDate, createdByUser }}">
        <div>{{ createdDate | moment('from', 'now') }}</div>
        <div>
          <span v-if="createdByUser && createdByUser.firstName"
                class="mr-1">
            {{ createdByUser.firstName }}
          </span>
          <span v-if="createdByUser && createdByUser.lastName">
            {{ createdByUser.lastName }}
          </span>
        </div>
      </template>

      <template #item.t_resolve="data">
        <span v-if="data.item.userResolvedDate">
          {{ data.item.userResolvedDate | moment('from', 'now') }}
        </span>
        <span v-else>
          <v-btn color="secondary"
                 outlined
                 small
                 @click="updateResignationErrorResolved(data.item)">Fixed
          </v-btn>
        </span>
      </template>

      <template #item.t_message="data">
        {{ data.item.resignationErrorMessage }}
      </template>

      <template #empty>
        <h2>No errors</h2>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { ResignationErrorApi } from '@/api/ResignationErrorApi';
import handleError from '@/errorHandler';

export default {
  name: 'ResignationErrorList',
  props: {
    resignationId: {
      type: Number,
      required: true,
    },
    resignationErrors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resignationErrorFields: [
        {
          text: 'Type',
          value: 'errorTypes',
          cellClass: ['fit', 'no-wrap'],
        },
        {
          text: 'Message',
          value: 't_message',
        },
        {
          text: 'Created',
          value: 't_createdDate',
          class: ['fit'],
          cellClass: 'no-wrap',
        },
        {
          text: 'Resolved',
          value: 't_resolve',
          cellClass: ['fit', 'no-wrap'],
        },
      ],
    };
  },
  methods: {
    async updateResignationErrorResolved(resignationError) {

      const confirmResolved = await this.$swal({
        title: 'Resolve?',
        text: 'Do you want to mark this issue as resolved? Once resolved, it will go back into the queue for attorney review.',
        showCancelButton: true,
        confirmButtonText: 'Issue Resolved',
      });

      if (confirmResolved.value) {

        try {
          await ResignationErrorApi.updateResignationErrorResolved(this.resignationId, resignationError.resignationErrorId);
          resignationError.userResolvedDate = new Date();

          this.$emit('resignation-error-resolved');
        } catch (e) {
          console.error(e);
          handleError(e);
        }
      }
    },
  },

};
</script>

<style scoped>

</style>
