












































import Component, { mixins } from 'vue-class-component';
import pdf from 'vue-pdf';
import { AdminResignationFileApi } from '@/api/AdminResignationFileApi';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { IResignationFileApprovalStatusUpdateRequest, ResignationFileApprovalType, IResignationFileView } from '@/interfaces/_auto/api';

import EnumsMixin from '@/mixins/EnumsMixin';


@Component({
  components: {
    pdf,
  },
})
export default class AdminResignationDocumentView extends mixins(EnumsMixin) {

  // resignationFileId
  @Prop({ type: Object, required: false, default: () => null })
  value!: IResignationFileView | null;

  @Prop({ type: Object, required: false, default: () => null })
  fileApprovalStatus!: ResignationFileApprovalType | null;

  currentPage = 1;
  numPages: number | undefined = 0;
  pdfData: string | null = null;
  imageData: string | null = null;

  get resignationFileId() {
    return this.value?.resignationFileId;
  }

  mounted() {
    this.loadAdminEnums();
    // this.src.promise.then(pdf => {
    //   debugger;
    //   this.numPages = pdf.numPages;
    // });
  }

  get prevEnabled() {
    return this.currentPage > 1;
  }

  get nextEnabled() {
    return this.currentPage < (this.numPages ?? 0);
  }

  onLoaded() {
    this.currentPage = 1;
  }

  onNumPages(numberOfPages) {
    this.numPages = numberOfPages;
  }

  async downloadDocumentAdmin(resignationFileId: number) {

    try {
      this.pdfData = null;
      this.imageData = null;

      const { data: { blobData, fileName } } = await AdminResignationFileApi.getFile(resignationFileId);

      if (fileName.endsWith('pdf')) {
        this.pdfData = `data:application/pdf;base64,${blobData}`;
      } else {
        this.imageData = `data:image/jpeg;base64,${blobData}`;
      }

    } catch (e) {
      this.handleError(e);
      console.error(e);
    }
  }

  @Emit('update:fileApprovalStatus')
  async updateFileApprovalStatus(newStatus) {

    if (!this.resignationFileId) {
      alert('Resignation File ID was null');
      return;
    }

    try {
      const request = {
        fileApprovalStatus: newStatus,
      } as IResignationFileApprovalStatusUpdateRequest;

      await AdminResignationFileApi.updateResignationFileApprovalStatus(this.resignationFileId, request);
      this.$toast.success('Approval Status saved');

      return newStatus;
    } catch (e) {
      this.handleError(e);
      console.error(e);
    }
  }


  @Watch('value', { immediate: true })
  async watchValue(newVal) {
    await this.downloadDocumentAdmin(newVal.resignationFileId);
  }

}
