import Vue from 'vue';
import Component from 'vue-class-component';
import { IResignationView, ResignationQueueStatus } from '@/interfaces/_auto/api';
import { SearchPage } from '@/interfaces/searchPage';

import { IExpandedEnum } from '@/interfaces/IExpandedEnum';

@Component
export default class AdminResignationStoreMixin extends Vue {

  async resetPageAndSearch() {
    // @ts-ignore
    await this.changePage({ currentPage: 1 });
    await this.searchResignationQueue();
  }

  async searchResignationQueue() {
    await this.$store.dispatch('adminResignationStore/searchResignationQueue');
  }

  async changePage(searchPage: Partial<SearchPage>) {
    this.$store.commit('adminResignationStore/changePage', searchPage);
  }

  get search() {
    return this.$store.state.adminResignationStore.search;
  }

  get searchPage(): SearchPage {
    return this.$store.state.adminResignationStore.search.searchPage;
  }

  get totalRecords(): number {
    return this.$store.state.adminResignationStore.totalRecords;
  }

  get resignationListLoading(): boolean {
    return this.$store.state.adminResignationStore.resignationListLoading;
  }

  get resignationList(): IResignationView[] {
    return this.$store.state.adminResignationStore.resignationList;
  }


  get searchTerm() {
    return this.$store.state.adminResignationStore.search.searchTerm;
  }

  set searchTerm(value: string) {
    this.$store.commit('adminResignationStore/changeSearch', { searchTerm: value });
  }

  get holdFlag() {
    return this.$store.state.adminResignationStore.search.holdFlag;
  }

  set holdFlag(value) {
    this.$store.commit('adminResignationStore/changeSearch', { holdFlag: value });
  }

  get duplicateFlag() {
    return this.$store.state.adminResignationStore.search.duplicateFlag;
  }

  set duplicateFlag(value) {
    this.$store.commit('adminResignationStore/changeSearch', { duplicateFlag: value });
  }

  get errorFlag() {
    return this.$store.state.adminResignationStore.search.errorFlag;
  }

  set errorFlag(value) {
    this.$store.commit('adminResignationStore/changeSearch', { errorFlag: value });
  }

  get hasApprovedDocuments() {
    return this.$store.state.adminResignationStore.search.hasApprovedDocuments;
  }

  set hasApprovedDocuments(value) {
    this.$store.commit('adminResignationStore/changeSearch', { hasApprovedDocuments: value });
  }

  get resignationQueueStatus() {
    return this.$store.state.adminResignationStore.search.resignationQueueStatus;
  }

  set resignationQueueStatus(value: IExpandedEnum<ResignationQueueStatus> | null | undefined) {
    this.$store.commit('adminResignationStore/changeSearch', { resignationQueueStatus: value });
  }

  get currentAdminIndex() {
    return this.$store.state.adminResignationStore.currentAdminIndex;
  }

  set currentAdminIndex(value) {
    this.$store.commit('adminResignationStore/saveCurrentAdminIndex', value);
  }

}
