











































































































































































































































































































































































































































































































import { AdminResignationApi } from '@/api/AdminResignationApi';
import handleError from '@/errorHandler';
import { AdminResignationFileApi } from '@/api/AdminResignationFileApi';
import ResignationErrorModal from '@/components/ResignationErrorModal.vue';
import ResignationErrorList from '@/components/ResignationErrorList.vue';
import Component, { mixins } from 'vue-class-component';
import resignationMixins from '@/mixins/resignationMixins';
import {
  IResignationView, IResignationFileView, ILegalApproveResignationRequest,
  ResignationFileApprovalType
} from '@/interfaces/_auto/api';
import { DataTableHeader } from 'vuetify';
import { Prop } from 'vue-property-decorator';
import pdf from 'vue-pdf';
import AdminResignationDocumentView from '@/views/Admin/AdminResignationDocumentView.vue';
import EntityDisplay from '@/components/EntityDisplay.vue';

import AdminResignationStoreMixin from '@/mixins/AdminResignationStoreMixin';


import { faDumpsterFire, faPaperPlane, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { IExpandedEnum } from '@/interfaces/IExpandedEnum';
import { buildResignationQueueSearch } from '@/store/modules/adminResignationStore';
import { AdminResignationQueueApi } from '@/api/adminResignationQueueApi';

@Component({
  components: {
    EntityDisplay,
    AdminResignationDocumentView,
    ResignationErrorList,
    ResignationErrorModal,
    pdf,
  },
})
export default class AdminResignationDetail extends mixins(resignationMixins, AdminResignationStoreMixin) {

  @Prop({ type: Number, required: true })
  // @ts-ignore
  resignationId!: number;

  @Prop({ type: Number, required: false, default: () => null })
  position!: number | null;

  selectedResignationFile: IResignationFileView | null = null;


  resignationFamily: IResignationView[] = [];


  uploadResignationFileList = [];
  uploadProgressPercent = 0;
  uploadInProgress = false;


  // totalRecords = 0;
  // currentAdminIndex = 0;
  // resignationList: IResignationView[] = [];
  // searchPage = new SearchPage();

  async mounted() {
    if (this.resignationId) {
      await this.adminGetResignation(this.resignationId);
    }


    if (this.position) {
      await this.loadResignationList();

    }

    // if (this.totalRecords === 0) {
    //   await this.$store.dispatch('adminResignationStore/searchResignationQueue');
    // }

    // this.setStoreCurrentIndex();
  }

  async loadResignationList() {
    const newPageSize = this.totalRecords > 0 ? this.totalRecords : 1;
    const search = buildResignationQueueSearch(this.search);
    search.searchPage.pageSize = newPageSize;
    search.searchPage.currentPage = 1;
    search.searchPage.pageIndex = 0;

    try {
      const { data } = await AdminResignationQueueApi.searchResignationQueue(search);
      await this.$store.commit('adminResignationStore/saveResignationSearchResults', data);

      this.currentAdminIndex = this.resignationList.findIndex(r => r.resignationId === this.resignationId) + 1;

    } catch (e) {
      console.error(e);
      handleError(e);
    }
  }

  async onErrorAdded() {
    await this.adminGetResignation(this.resignationId);
  }

  get hasOneResignationFileSendToKmc() {
    if (!this.resignation) {
      return false;
    }

    if (!this.resignation.resignationFiles) {
      return false;
    }

    const findIndex = this.resignation.resignationFiles.findIndex(rf =>
      (rf.fileApprovalStatus as unknown as IExpandedEnum<ResignationFileApprovalType>).number === ResignationFileApprovalType.ApprovedToSend);
    return findIndex > -1;
  }

  get hasUserApprovedResignation() {
    return !!this.resignation?.userApprovedResignationDate;
  }

  get approveResignationEnabled() {

    if (!this.hasOneResignationFileSendToKmc) {
      return false;
    }

    if (!this.resignation?.userApprovedResignationDate) {
      return false;
    }

    if (this.resignation?.attorneyReviewedDate) {
      return false;
    }

    return true;
  }

  async adminGetResignation(resignationId) {
    try {
      const { data } = await AdminResignationApi.getResignationById(resignationId);
      this.resignation = data;
    } catch (e) {
      this.handleError(e);
    }

    try {
      await this.getResignationsByQmUserId();
    } catch (e) {
      this.handleError(e);
    }
  }


  async getResignationsByQmUserId() {

    if (this.resignation) {
      const { data } = await AdminResignationApi.getResignationsByQmUserId(this.resignation.qmUserId);
      this.resignationFamily = data;
    }
  }

  async approveResignation() {

    if (!this.resignation) {
      alert('Resignation is null');
      return;
    }

    try {
      const legalApproveResignationRequest = {
        resignationId: this.resignationId,
      } as ILegalApproveResignationRequest;
      await AdminResignationApi.legalApproveResignation(legalApproveResignationRequest);
      this.$toast.success(`Resignation ${this.resignationId} approved`);
      this.resignation.attorneyReviewedDate = new Date();
      // await this.moveNext();
    } catch (e) {
      this.handleError(e);
    }
  }

  async approveAndMoveNext() {
    await this.approveResignation();
    await this.moveNext();
  }

  async updateResignationConfirmationReceived() {
    try {
      const approveResignationRequest = {
        resignationId: this.resignationId,
      };
      await AdminResignationApi.updateResignationConfirmationReceived(approveResignationRequest);
      this.$toast.success(`Resignation ${this.resignationId} confirmed`);
      await this.moveNext();
    } catch (e) {
      this.handleError(e);
    }
  }


  async toggleResignationHold() {
    if (!this.resignation) {
      alert('resignation is null');
      return;
    }

    if (!this.resignation.holdFlag) {
      await this.holdResignation();
    } else {
      await this.removeResignationHold();
    }
  }


  async holdResignation() {
    if (!this.resignation) {
      alert('resignation is null');
      return;
    }

    const result = await this.$swal({
      title: 'Place resignation on hold?',
      input: 'text',
      text: 'Placing the resignation on hold will prevent it from showing in the approval queue, or any action being taken on the resignation.' +
        'The user is NOT notified. ' +
        'Optionally, provide a reason:',
      showCancelButton: true,
    });

    if (result.dismiss) {
      return;
    }

    const holdReason = result.value as string;

    const resignationHoldRequest = {
      resignationId: this.resignation.resignationId,
      holdFlag: true,
      holdReason: holdReason,
    };
    await AdminResignationApi.updateResignationHold(resignationHoldRequest);
    this.resignation.holdFlag = true;
    this.resignation.holdReason = holdReason;
    this.$toast.success('Resignation placed on hold');

  }

  async removeResignationHold() {

    if (!this.resignation) {
      alert('resignation is null');
      return;
    }

    const result = await this.$swal({
      title: 'Remove Hold?',
      text: 'Remove resignation hold?',
      showCancelButton: true,
    });

    if (result.value) {
      const resignationHoldRequest = {
        resignationId: this.resignation.resignationId,
        holdFlag: false,
        holdReason: '',
      };
      await AdminResignationApi.updateResignationHold(resignationHoldRequest);
      this.resignation.holdFlag = false;
      this.$toast.success('Resignation hold removed');
    }

  }

  get canMoveNext() {
    return this.currentAdminIndex < this.totalRecords;
  }

  get canMoveBack() {
    return this.currentAdminIndex > 1;
  }


  async moveNext() {
    this.currentAdminIndex++;
    await this.navigateToResignation();
  }

  async moveBack() {
    this.currentAdminIndex--;
    await this.navigateToResignation();
  }

  async navigateToResignation() {

    const nextResignation = this.resignationList[this.currentAdminIndex - 1];

    if (nextResignation) {
      await this.$router.push({
        name: 'admin-resignation-detail',
        params: { resignationId: nextResignation.resignationId.toString() },
      });
    }
  }


  async toggleResignationDuplicate() {

    if (!this.resignation) {
      alert('resignation is null');
      return;
    }

    try {
      const duplicateRequest = {
        resignationId: this.resignationId,
        duplicateFlag: !this.resignation.duplicateFlag,
      };

      await AdminResignationApi.UpdateResignationDuplicate(duplicateRequest);
      this.resignation.duplicateFlag = duplicateRequest.duplicateFlag;

    } catch (e) {
      console.error(e);
      this.handleError(e);
    }
  }


  async resendResignationDocuments() {

    const shouldResend = await this.$swal({
      title: 'Resend documents to user?',
      text: 'Are you sure you want to resend documents to this user?',
      showCancelButton: true,
      icon: 'question',
    });

    if (shouldResend.value) {
      try {
        await AdminResignationApi.resendResignationDocuments(this.resignationId);
        this.$toast.success('Sent');
      } catch (e) {
        this.handleError(e);
      }
    }

  }

  async downloadDocumentAdmin(resignationFileId: number) {

    try {
      const { data } = await AdminResignationFileApi.getFile(resignationFileId);

      let decoded = atob(data.blobData);
      decoded = this.stringToBytes(decoded);

      const blob = new Blob([decoded], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.fileName);
      document.body.appendChild(link);
      link.click();

    } catch (e) {
      console.error(e);
      handleError(e);
    }

  }


  async uploadResignationFiles() {
    if (this.uploadResignationFileList.length === 0) {
      await this.$swal('No files selected', 'Please select one or more files and try again', 'warning');
      return;
    }

    try {
      this.uploadInProgress = true;

      await AdminResignationFileApi.uploadResignationDocuments(this.resignationId, this.uploadResignationFileList, (progress) => {
        const { total, loaded } = progress;
        this.uploadProgressPercent = (loaded / total * 100) - 1;
      });

      await this.$swal({
        title: 'Success',
        text: 'Uploaded successfully!',
        icon: 'success',
      });

      this.uploadResignationFileList = [];
      this.uploadProgressPercent = 0;

      await this.adminGetResignation(this.resignationId);

    } catch (e) {
      handleError(e, 'Upload failed');
      console.error(e);
    } finally {
      this.uploadInProgress = false;
    }
  }


  async deleteDocumentAdmin(resignationFileId: number) {

    if (!this.resignation) {
      alert('resignation is null');
      return;
    }

    if (!this.resignation.resignationFiles) {
      alert('resignation files property is null');
      return;
    }

    const { value } = await this.$swal({
      title: 'Delete Document?',
      icon: 'warning',
      text: 'Are you sure you want to delete this file?',
      confirmButtonText: 'Delete Document',
      showCancelButton: true,
    });

    if (value) {
      try {
        console.log('Deleting file', resignationFileId);

        await AdminResignationFileApi.deleteFile(resignationFileId);
        this.$toast.success('Deleted file');

        const idx = this.resignation.resignationFiles.findIndex(rf => rf.resignationFileId === resignationFileId);

        if (idx > -1) {
          this.resignation.resignationFiles.splice(idx, 1);
        }

      } catch (e) {
        console.error(e);
        handleError(e);
      }
    }
  }

  getFileApprovalStatusIcon(file: IResignationFileView) {
    const approvalType = file.fileApprovalStatus as unknown as IExpandedEnum<ResignationFileApprovalType>;

    switch (approvalType.number) {

      case ResignationFileApprovalType.NotReviewed:
        return faQuestion;

      case ResignationFileApprovalType.ApprovedToSend:
        return faPaperPlane;

      case ResignationFileApprovalType.RejectedIncomplete:
      case ResignationFileApprovalType.RejectedIllegible:
        return faDumpsterFire;

      default:
        return faQuestion;
    }
  }

  async deleteResignation({ resignationId }: { resignationId: number }) {

    const confirmDelete = await this.$swal({
      title: 'Delete?',
      text: 'Do you want to delete this resignation?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (confirmDelete.value) {

      try {
        await AdminResignationApi.deleteResignation(resignationId);
        if (this.resignation) {
          this.resignation.deleteFlag = true;
        }
      } catch (e) {
        this.handleError(e);
        console.error(e);
      }
    }

  }

  get resignationHeaders() {
    return [
      {
        text: 'Res Name(s)',
        value: 'resignationNames',
      },

      {
        text: 'Age',
        value: 'birthDate',
        formatter: (val) => this.$moment(val).from(new Date(), true),
      },
      {
        text: 'Birth Date',
        value: 'birthDate',
        formatter: (val) => this.$moment(val).format('ll'),
      },
      {
        value: 'membershipNumber',
        hide: (val) => !val,
      },
      {
        text: 'Minor',
        value: 'minorFlag',
        formatter: (val) => val ? 'Yes' : 'No',
      },
      {
        text: 'Parent One',
        value: 'parentOneName',
        hide: (val) => !val,
      },
      {
        text: 'Parent Two',
        value: 'parentTwoName',
        hide: (val) => !val,
      },
      {
        text: 'Parent Two not required',
        value: 't_parentTwoNotRequiredFlag',
        hide: (val) => !val,
      },
      {
        text: 'Name on account',
        value: 'account_name',
      },
      {
        text: 'Email',
        value: 'qmUser.emailAddress',
      },
    ];
  }

  get resignationHeadersAddress() {
    return [
      {
        text: 'Address Line 1',
        value: 'address.streetAddress1',
      },
      {
        text: 'Address Line 2',
        value: 'address.streetAddress2',
        hide: (val) => !val,
      },
      {
        text: 'City',
        value: 'address.city',
      },
      {
        text: 'State / Province',
        value: 'address.stateProvince.stateProvinceName',
      },
      {
        text: 'Country',
        value: 'address.country.countryName',
      },
      {
        text: 'Post Code',
        value: 'address.postalCode',
      },
    ];
  }

  get resignationDatesHeaders() {
    const momentFormatter = (val) => val ? this.momentFilter(val, 'lll') + ' (' + this.momentFilter(val, 'from', 'now') + ')' : '';

    return [
      {
        text: 'Created',
        value: 'createdDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
      }, {
        text: 'Modified',
        value: 'modifiedDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      }, {
        text: 'User Approved',
        value: 'userApprovedResignationDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      }, {
        text: 'Attorney Reviewed',
        value: 'attorneyReviewedDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      }, {
        text: 'Submitted To Membership',
        value: 'submittedToMembershipDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      }, {
        text: 'Confirmation Received',
        value: 'confirmationReceivedDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      }, {
        text: 'Confirmation Downloaded',
        value: 'confirmationDownloadedDate',
        class: 'text-nowrap',
        formatter: momentFormatter,
        hide: (val) => !val,
      },
    ];
  }

  get resignationFamilyHeaders(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 't_item-icon',
        cellClass: ['fit'],
      },
      {
        text: 'Name',
        value: 'fullName',
      },
      {
        text: 'Age',
        value: 't_age',
      },
      {
        text: 'Cnf',
        value: 't_confirmed',
      },
    ];
  }

  get resignationFileHeaders(): DataTableHeader[] {
    return [
      {
        text: '',
        value: 't_download',
      },
      {
        text: 'File Name',
        value: 'fileName',
      },
      {
        text: 'Size',
        value: 'fileSizeDescription',
      },
      {
        text: 'Type',
        value: 'fileType.description',
      },
      {
        text: 'Uploaded',
        value: 't_created',
      },
      {
        text: '',
        value: 't_delete',
      },
    ];
  }

  get momentFilter() {
    // @ts-ignore
    return this.$options.filters.moment;
  }
}
