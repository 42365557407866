//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { SearchPage } from '@/interfaces/searchPage';
import { IExpandedEnum } from '@/interfaces/IExpandedEnum';

export interface IFileBytesView
{
  fileName: string;
  blobData: string;
}
export interface IWebsiteSettings
{
  debug: boolean;
  sentryDsn?: string;
  auth: IWebsiteAuthSettings;
  environment: IWebsiteEnvironmentSettings;
  unleashSettings: IWebsiteUnleashSettings;
}
export interface IWebsiteAuthSettings
{
  clientId?: string;
  authority?: string;
  realm?: string;
}
export interface IWebsiteEnvironmentSettings
{
  environmentName?: string;
  webVersion?: string;
}
export interface IWebsiteUnleashSettings
{
  proxyUrl?: string;
  proxySecret?: string;
}
export interface I_AdminEnumDictionary
{
  resignationQueueStatusOptions: ResignationQueueStatus[];
  resignationErrorOptions: ResignationErrorType[];
  resignationFileApprovalTypes: ResignationFileApprovalType[];
}
export interface IAdminSummaryView
{
  totalResignations: number;
  awaitingAttorneyReview: number;
  awaitingSendToKirton: number;
  awaitingConfirmation: number;
  resignationsConfirmed: number;
  resignationsHoldCount: number;
}
export interface IResignationFileApprovalStatusUpdateRequest
{
  fileApprovalStatus: ResignationFileApprovalType;
}
export interface ILegalApproveResignationRequest
{
  resignationId?: number | null;
}
export interface IResignationErrorUpdateRequest
{
  resignationId?: number | null;
  resignationErrorTypes?: ResignationErrorType[];
  message?: string;
}
export interface IResignationQueueSearchRequest
{
  searchPage: SearchPage;
  searchTerm?: string;
  resignationQueueStatus?: ResignationQueueStatus | null;
  holdFlag?: boolean | null;
  duplicateFlag?: boolean | null;
  errorFlag?: boolean | null;
  hasApprovedDocuments?: boolean | null;
  submittedDaysAgo?: number | null;
}
export enum ResignationQueueStatus {
  AwaitingAttorneyReview = 1,
  WaitingForConfirmation = 2,
  Confirmed = 4,
  WaitingForSendToKmc = 5
}
export interface IUserApproveResignationRequest
{
  resignationId?: number | null;
  agreeToTerms?: boolean | null;
  agreeToDataStorage?: boolean | null;
}
export interface IUserView
{
  username: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
}
export enum ResignationFileApprovalType {
  NotReviewed = 1,
  ApprovedToSend = 2,
  RejectedIncomplete = 3,
  RejectedIllegible = 4,
  Ignore = 4
}
export enum ResignationFileType {
  User_Uploaded = 1,
  Confirmation_Letter = 2,
  Identification = 3,
  NotarizedDocuments = 4,
  SupportingDocumentation = 5
}
export interface IResignationFileView
{
  resignationFileId: number;
  fileName?: string;
  fileSize: number;
  contentType?: string;
  fileType: ResignationFileType;
  createdDate: any;
  fileSizeDescription: string;
  fileApprovalStatus: ResignationFileApprovalType;
  fileApprovalStatusDate?: any | null;
  fileApprovalStatusUser?: any;
}
export interface IAddressUpdateRequest
{
  countryId?: number | null;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  stateProvinceId?: number | null;
  provinceName: string;
  postalCode: string;
}
export interface IAddressView
{
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  provinceName?: string;
  postalCode?: string;
  stateProvince?: IStateProvinceView;
  country?: ICountryView;
}
export interface ICountryView
{
  countryId: number;
  countryName?: string;
  countryCode2?: string;
  countryCode3?: string;
}
export enum GenderType {
  ChooseNotToDisclose = 1,
  Male = 2,
  Female = 3,
  Other = 4
}
export enum ResignationErrorType {
  MissingSignature = 1,
  InvalidSignature = 2,
  MissingParentSignature = 3,
  Other = 4,
  MissingNotary = 5,
  MissingPages = 6,
  LowRes = 7,
  Illegible = 8
}
export interface IResignationErrorView
{
  resignationErrorId: number;
  errorTypes?: ResignationErrorType[];
  resignationErrorMessage?: string;
  userResolvedDate?: any | null;
  createdDate?: any | null;
  createdByUser?: IUserView;
}
export interface IResignationUpdateRequest
{
  fullName?: string;
  resignationNames?: IResignationNameUpdateRequest[];
  parentOneName?: string;
  parentTwoName?: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  membershipNumber?: string;
  minorFlag: boolean;
  parentTwoNotRequiredFlag: boolean;
  address?: IAddressUpdateRequest;
}
export interface IResignationNameUpdateRequest
{
  firstName?: string;
  middleName?: string;
  familyName?: string;
  isPrimary: boolean;
}
export interface IResignationView
{
  resignationId: number;
  qmUserId?: string;
  fullName?: string;
  resignationNames?: any[];
  parentOneName?: string;
  parentTwoName?: string;
  membershipNumber?: string;
  minorFlag: boolean;
  parentTwoNotRequiredFlag: boolean;
  allowStatsFlag: boolean;
  genderType: GenderType;
  membershipYears?: number | null;
  membershipActiveFlag?: boolean | null;
  createdDate: any;
  modifiedDate?: any | null;
  documentsDownloadedDate?: any | null;
  userApprovedResignationDate?: any | null;
  attorneyReviewedDate?: any | null;
  submittedToMembershipDate?: any | null;
  confirmationReceivedDate?: any | null;
  confirmationDownloadedDate?: any | null;
  birthMonth: number;
  birthDay: number;
  birthYear: number;
  birthDate: any;
  address?: IAddressView;
  holdFlag: boolean;
  holdReason?: string;
  duplicateFlag: boolean;
  errorFlag: boolean;
  deleteFlag: boolean;
  qmUser?: IUserView;
  resignationFiles?: IResignationFileView[];
  resignationErrors?: IResignationErrorView[];
}
export interface IStateProvinceView
{
  stateProvinceId: number;
  stateProvinceName: string;
}
export interface IEventSearchRequest
{
  searchPage: SearchPage;
  deleteFlag: boolean;
}
export interface IEventUpdateRequest
{
  eventName?: string;
  eventTime?: string;
  presentedBy?: string;
  description?: string;
  link?: string;
}
export interface IEventView
{
  eventId: number;
  eventName: string;
  eventTime: string;
  presentedBy?: string;
  description?: string;
  link: string;
  createdDate?: any | null;
  modifiedDate?: any | null;
  deleteFlag: boolean;
}
