<template>
  <div>
    <slot v-if="title"
          name="title">
      <div class="title">
        {{ title }}
      </div>
    </slot>
    <v-simple-table :dense="dense">
      <tbody>
        <tr :key="idx"
            v-for="(header, idx) in filteredHeaders"
            v-show="!shouldHideRow(header)">
          <td class="text-right label fit">
            {{ getEntityLabel(header) }}
          </td>
          <td>
            <slot :item="value"
                  :name="getItemSlotName(header)">
              <div :class="header.class"
                   class="value">
                {{ getEntityValue(header) }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EntityDisplay',
  mixins: [],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    title: {
      type: String,
      required: false,
      default: () => null
    },
    dense: {
      type: Boolean,
      required: false,
      default: () => false
    },
    headers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    getItemSlotName(header) {
      return `item.${header.value}`;
    },
    getEntityLabel(header) {
      if (header && header.text) {
        return header.text;
      } else {
        return _.startCase(header.value);
      }
    },
    shouldHideRow(header) {

      if (!header.hide) {
        return false;
      }

      const value = _.get(this.value, header.value);

      return header.hide(value);
    },
    getEntityValue(header) {
      let value;

      if (header.formatter) {
        value = header.formatter(_.get(this.value, header.value));
      } else {
        value = _.get(this.value, header.value);
      }

      return value;
    }
  },
  computed: {
    keyValuePairs() {

      if (!this.value) {
        return [];
      }

      const arr = [];
      for (const key in this.value) {
        arr.push({
          key,
          value: this.value[key]
        });
      }

      return arr;
    },
    filteredHeaders() {
      return this.headers;
    }
  },
  watch: {}
};
</script>

<style lang="scss"
       scoped>
.label {
  width: 1%;
  white-space: nowrap;
  color: #6b6b6b;
}

.value {

}
</style>
