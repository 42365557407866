var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px"},on:{"input":_vm.onShown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",function(){return [_vm._v(" Hook up slot ")]},{"attrs":attrs,"on":on})]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formErrors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-form',{ref:"errorForm"},[_c('v-card',[_c('v-card-title',[_vm._v(" Add Error "+_vm._s(_vm.resignationId)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas','times'],"size":"lg"}})],1)],1),_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_c('ul',{staticClass:"py-0 my-0"},[_c('li',[_vm._v(" An email will be sent to the user so they can resolve this error. ")]),_c('li',[_vm._v(" A message will be automatically added to the email for each checked error. ")])])]),_c('validation-provider',{attrs:{"custom-messages":{ required: 'At least one error type must be selected' },"name":"Error Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.adminEnums.resignationErrorOptions),function(e){return _c('v-checkbox',{key:e.number,attrs:{"dense":"","error":validated && !!errors.length > 0,"hide-details":"","label":e.description,"value":e.value},model:{value:(_vm.resignationErrorTypes),callback:function ($$v) {_vm.resignationErrorTypes=$$v},expression:"resignationErrorTypes"}})})}}],null,true)}),_c('v-textarea',{staticClass:"mt-4",attrs:{"label":"Additional Message:","outlined":"","rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),(invalid && validated)?_c('v-alert',{attrs:{"text":"","type":"error"}},[_c('ul',{staticClass:"my-0 py-0"},_vm._l((formErrors),function(e,ix){return _c('li',{key:ix},[_vm._v(" "+_vm._s(e[0])+" ")])}),0)]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addResignationError}},[_vm._v(" Add error & email user ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }