import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';

const API_CONTROLLER = 'admin/resignation/error';

export class AdminResignationErrorApi {

  static addResignationError(resignationErrorUpdateRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}`;
    return authAxios.post(url, resignationErrorUpdateRequest);
  }
}
