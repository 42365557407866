import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';
import { IResignationView } from '@/interfaces/_auto/api';
import { AxiosPromise } from 'axios';

const API_CONTROLLER = 'admin/resignation';

export class AdminResignationApi {

  static getResignationById(resignationId: number): AxiosPromise<IResignationView> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`;
    return authAxios.get(url);
  }

  static getResignationsByQmUserId(qmUserId): AxiosPromise<IResignationView[]> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/for-user/${qmUserId}`;
    return authAxios.get(url);
  }

  static legalApproveResignation(legalApproveResignationRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/legal-approve`;
    return authAxios.post(url, legalApproveResignationRequest);
  }

  static updateResignationConfirmationReceived(approveResignationRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/confirmation-received`;
    return authAxios.post(url, approveResignationRequest);
  }

  static updateResignationHold(resignationHoldRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/hold`;
    return authAxios.post(url, resignationHoldRequest);
  }

  static resendResignationDocuments(resignationId) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/resend-documents/${resignationId}`;
    return authAxios.post(url);
  }

  static UpdateResignationDuplicate(duplicateRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/duplicate`;
    return authAxios.post(url, duplicateRequest);
  }

  static deleteResignation(resignationId: number) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`;
    return authAxios.delete(url);
  }
}
