



































































































import Vue from 'vue';
import { AdminResignationErrorApi } from '@/api/AdminResignationErrorApi';
import handleError from '@/errorHandler';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { ResignationErrorType, IResignationErrorUpdateRequest } from '@/interfaces/_auto/api';


@Component
export default class ResignationErrorModal extends Vue {

  @Prop({ type: Number, required: true })
  resignationId!: number;

  showDialog = false;
  resignationErrorTypes: ResignationErrorType[] = [];
  message: string | null = null;

  async mounted() {
    await this.$store.cache.dispatch('enums/getAdminEnums');
  }

  async onShown() {
  }

  async addResignationError() {

    const isValid = await this.validationObserver.validate();
    if (!isValid) {
      console.log('Form is not valid');
      return;
    }

    if (this.resignationErrorTypes.length === 0) {
      alert('Select an error type');
      return;
    }

    const resignationErrorUpdateRequest: IResignationErrorUpdateRequest = {
      resignationId: this.resignationId,
      resignationErrorTypes: this.resignationErrorTypes,
      message: this.message ?? undefined,
    };

    try {
      await AdminResignationErrorApi.addResignationError(resignationErrorUpdateRequest);
      this.$toast.success('Error has been added');
      this.closeDialog();
      this.emitErrorAdded();
    } catch (e) {
      console.error(e);
      handleError(e);
    }
  }

  closeDialog() {
    this.showDialog = false;
  }

  @Emit('error-added')
  emitErrorAdded() {
    /* return void */
  }

  get form() {
    return this.$refs.errorForm as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }

  get validationObserver() {
    return this.$refs.validationObserver as Vue & {
      validate: () => Promise<boolean>;
      reset: () => boolean;
    };
  }

  get adminEnums() {
    return this.$store.state.enums.adminEnums;
  }
}

