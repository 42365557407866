import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';

const API_CONTROLLER = 'resignation/error';

export class ResignationErrorApi {

  static updateResignationErrorResolved(resignationId, resignationErrorId) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}/resolve/${resignationErrorId}`;
    return authAxios.put(url);
  }

}
